<!--  -->
<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="所属社区" prop="communityCode">
          <el-select clearable
              v-model="formInline.communityCode"
              placeholder="请选择社区">
            <el-option
                v-for="item in communitys"
                :key="item.levelcode"
                :label="item.name"
                :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="社员姓名">
          <el-input v-model="formInline.name"></el-input>
        </el-form-item>
        <el-form-item label="政治面貌">
          <el-select v-model="formInline.political" placeholder="请选择政治面貌">
          <el-option
              v-for="item in politic_List"
              :key="item.label"
              :label="item.label"
              :value="item.label">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="是否为特殊困难群体">
          <el-select style="width: 220px;" clearable v-model="formInline.health" placeholder="请选择是否为特殊困难群体">
            <el-option label="否" value="否"></el-option>
            <el-option label="低保边缘户" value="低保边缘户"></el-option>
            <el-option label="低保户" value="低保户"></el-option>
            <el-option label="残障" value="残障"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="primary" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        style="width: 100%"
        id="exportTable"
        :header-cell-style="{fontWeight: '700', fontSize: '14px'}"
        :index="indexMethod">
        <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
        <el-table-column prop="communityName" label="社区" align="center"></el-table-column>
        <el-table-column prop="name" label="社员姓名" align="center"></el-table-column>
        <el-table-column prop="politicalStatus" label="政治面貌" align="center"></el-table-column>
        <el-table-column prop="health" label="是否为特殊困难群体" align="center"></el-table-column>
        <el-table-column prop="totalPoint" label="总共赚取积分" align="center"></el-table-column>
        <el-table-column prop="generalPoint" label="消费积分" align="center"></el-table-column>
        <el-table-column prop="honerPoint" label="荣誉积分" align="center"></el-table-column>
        <el-table-column prop="usedPoint" label="已花费积分" align="center"></el-table-column>
        <el-table-column prop="surplusPoint" label="剩余积分" align="center"></el-table-column>
        <el-table-column prop="actTimes" label="参加活动场数" align="center"></el-table-column>
        <el-table-column prop="volActTimes" label="参加任务场数" align="center"></el-table-column>
        <el-table-column prop="shopCount" label="消费店家数量" align="center"></el-table-column>
        <el-table-column prop="actCategory" label="参加频次最高的活动类别" align="center"></el-table-column>
        <el-table-column prop="consumeShop" label="消费频次最高的店铺" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { volSummaryList, exportExcel } from '@/api/reportStatistics'
import {communityOfUser} from "@/api/system";
export default {
  data () {
    return {
      tableData: [],
      formInline: {
        communityCode: '',
        name: '',
        political: '',
        health: ''
      },
      communitys: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      politic_List: [
        {label: '群众', value: '4'},
        {label: '中共党员', value: '1'},
        {label: '共青团员', value: '3'},
        {label: '民革党员', value: '5'},
        {label: '民盟盟员', value: '6'},
        {label: '民建会员', value: '7'},
        {label: '民进会员', value: '8'},
        {label: '农工党党员', value: '9'},
        {label: '致公党党员', value: '10'},
        {label: '九三学社社员', value: '11'},
        {label: '台盟盟员', value: '12'},
        {label: '无党派人士', value: '13'},
      ],
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getCommunityOfUser();
    this.onSearch();
  },

  methods: {
    getCommunityOfUser() {
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res => {
        if (res.result == 200) {
          this.communitys = res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      volSummaryList({
        communitycode: this.formInline.communityCode,
        name: this.formInline.name,
        political: this.formInline.political,
        health: this.formInline.health,
        pageSize: this.pageSize,
        page: this.currentPage,
      }).then(res => {
        if(res.result == 200) {
          let list = res.detail.list;
          list.map(item => {
            item.surplusPoint = parseInt(item.totalPoint) - parseInt(item.usedPoint);
          })
          this.tableData = list;
          this.totalRow = res.detail.totalRow;
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    exportXcle() {
      exportExcel({
        type: 2,
        communitycode: this.formInline.communityCode,
        name: this.formInline.name,
        political: this.formInline.political,
        health: this.formInline.health
      }).then(res => {
        const content = res;
        const blob = new Blob([content]) //构造一个blob对象来处理数据
        const fileName =  '沿江街道社员相关信息统计.xlsx';
        const link = document.createElement('a') //创建a标签
        link.download = fileName //a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click() //执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link) //释放标签
      })
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 20px 10px;
}
.pagebox{
  margin: 20px 0;
  text-align: right;
}
</style>